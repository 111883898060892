














































































































































































































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import UserService from "@/services/user.service";
import BaseVue from "@/core/BaseVue";
import { AuthenticationContext } from "vue-adal"; // This will be populated with a valid context after initialization
import User from "@/models/security/user.model";
import PincodeInput from "vue-pincode-input";
import AppUserRecoveryQuestion from '../../models/security/appUserRecoveryQuestion.model';
import SDSADConnectorService from '@/services/sdsadConnector.service';
@Component({
  components: { PincodeInput },
})
export default class WayToRecoveryPassword extends BaseVue {
  public show: boolean = false;
  public userService = new UserService();
  public model: User = new User();
  public waysToReveryPassword: Array<string> = [];
  public isLoading: boolean = false;
  public wayToComunicateSelected: string = "";
  public email: string = "";
  public principalEmail: string = "";
  public verificactionCode: string = "";
  public codeVerificationSended = false;
  public emailNoExist:boolean = false;
  public isEmailNotQuestion?:boolean | null;
  public isFirsTime = true;
  public selectedTheVia:boolean = false; 
  public userQuestions:Array<AppUserRecoveryQuestion> = [];
  public isReseting =  false;
  public isStillShowing = false;
  public sdsadConnectorService: SDSADConnectorService = new SDSADConnectorService();
  isAuthMethodQuestionActive = false;
  constructor() {
    super();
    this.isEmailNotQuestion = null;
  }
   mounted() {
    this.show = true;
    this.email = this.$route.params.id;
    this.getRecoveryWays();
  }


 validateAnswer(id:number,indexs:number){
  let inputId = 'input_id'+id;
  let result:any;
  let inputValue:any;
   inputValue = document.getElementById(inputId) ;
   let answer = inputValue.value as string;
  for (let index = 0; index < this.userQuestions.length; index++) {
    const currentQuestion = this.userQuestions[index];
    if(currentQuestion.id == id){
      if(currentQuestion.answer){
         let arr1 = currentQuestion.answer.split("");
         let arr2 =   answer.split("");
            if(arr1.sort().join(',') === arr2.sort().join(',')){
           currentQuestion.isSameAnswer = true;
           setTimeout(() => {
           this.isReseting = true;
           this.isReseting = false;
            }, 0);
           }else{
             currentQuestion.isSameAnswer = false;
            setTimeout(() => {
            this.isReseting = true;
            this.isReseting = false;
          }, 0);
        }
      }
    }
  }
 }
verifyQuestionIsSucess(){
  for (let index = 0; index < this.userQuestions.length; index++) {
    const currentQuestion = this.userQuestions[index];
      if(!currentQuestion.isSameAnswer){
           this.$buefy.toast.open({
            message: 'Please make sure that all question are right',
            type: 'is-warning',
            duration: 4000,
            position: "is-top-right",
        });
        return;
      }else{
          this.userService.createAGeneratedCode(this.email).then((resp:any) =>{
            debugger;
      this.$router.push(
        `/forgotPassword/${this.email}/waysTorecoveryPassword/recoveryn/${resp.generatedCode}/password`
      );
          })
       
      }
  }
}
  public getRecoveryWays(): void {
    this.isLoading = true;
    this.userService
      .getWaysToRecoveryPasswordByEmail(this.email)
      .then((resp) => {
        debugger;
        this.waysToReveryPassword = resp;
        if(!this.waysToReveryPassword.length){
            this.emailNoExist = true;
        }
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  @Watch("isEmailNotQuestion")
    onIsEmailNotQuestionChange(value:boolean){
        this.isFirsTime = false;
        
        if(value){
          this.selectedTheVia = true;
          this.isStillShowing =  true;
        }else{
          this.userService.getUserQuestion(this.email).then((resp:[]) =>{
          let questions =  resp.filter((x:AppUserRecoveryQuestion) => x.isRequired)
            for (let index = 0; index < 4; index++) {
                 let obj:any;
                  obj =  questions[Math.floor(Math.random()*questions.length)]
                  if(!this.userQuestions.some(x => x.questionId == obj.questionId)){
                    obj.isSameAnswer = null;
                   this.userQuestions.push(obj);
                  }
            }
          });

        }
    }

  public sendEmailOrSms(): void {
    this.selectedTheVia = false;
    this.isLoading = true;
    if (this.verificactionCode) {
      this.getGeneratedCode();
    } else {
      if (this.wayToComunicateSelected) {
        if (this.wayToComunicateSelected.includes("@")) {
          this.sendEmail();
        } else {
          this.sendSms();
        }
      } else {
          this.$buefy.toast.open({
            message: 'Your must select a method to recover your password',
            type: 'is-warning',
            duration: 4000,
            position: "is-top-right",

        });

      }
    }
  }

  public getGeneratedCode(): void {
    debugger;
    let codeGenerated = "";
    this.userService.getGenerateCode(this.verificactionCode).then((resp) => {
      codeGenerated = resp.generatedCode;
    }).finally(() =>{
      if(codeGenerated){
            this.$router.push(
        `/forgotPassword/${this.email}/waysTorecoveryPassword/recoveryn/${codeGenerated}/password`
      );
      }else{
    this.isLoading = false;

          this.$buefy.toast.open({
            message: `Sorry, this verification code does not exist or is already used`,
            type: 'is-warning',
            duration: 4000,
            position: "is-top-right",
        });
      }
    })
  }

  public sendEmail(): void {
    this.userService.sendEmail(this.wayToComunicateSelected, this.email).finally(() =>{
      this.isLoading = false;
    })
    this.codeVerificationSended = true;
    this.isEmailNotQuestion = true;
  
  }

  public sendSms(): void {
    this.userService.sendSms(this.wayToComunicateSelected.replace("+", "")).finally(() =>{
      this.isLoading = false;
    })
    this.codeVerificationSended = true;
    this.isEmailNotQuestion = true
  }
}
